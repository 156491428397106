// (GradeAUser);
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { Card, Divider, Grid, CardContent, Button } from '@material-ui/core';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import { gridSpacing } from '../../store/constant';
import { deleteUser, userList } from '../../actions/user';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import '@coreui/coreui/dist/css/coreui.css';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { CNavLink, CBadge, CRow, CCol, CCard, CButton, CCollapse, CCardBody } from '@coreui/react';
import { CDataTable } from '@coreui/react';
import IssueCertificate from './NotificationSection';

const useStyles = makeStyles({
    table: {
        minWidth: 350,
    },
    imgproduct: {
        width: '20px',
        height: 'auto',
    },
    title: {
        background: 'darkgreen',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
    },
    view: {
        background: '#eee',
        color: '#fff',
    },
    edit: { background: '#4CAF50', color: '#fff' },
    delete: { background: '#d11a2a', color: '#fff' },
    icons: {
        width: '18px',
        height: '18px',
    },
});

const GradeAUser = () => {
    const [data, setData] = useState()
       

    const AllUsers = data;
    const dispatch = useDispatch();
    const classes = useStyles();
     useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer null");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch("https://alive-tuna-cuff-links.cyclic.app/api/v1/checkListCat", requestOptions)
        .then(response => response.json())
        .then(result => setData(result?.data?.checklistparent))
        .catch(error => console.log('error', error));
    }, [dispatch]);
    const usersData = AllUsers?.map((item, index) => {
        return {
            TodayFixNumber: item.sign1,
            MotorPatti: item.sign2 ? item.sign2 : 'N/A',
            JodiBracket: item.sign3 ? item.sign3 : 'N/A',
            CyclePatti: item.sign4 ? item.sign5 : 'N/A',
            // Action: item._id,
        };
    });

    const getId = (_id) => {
        return _id;
    };
    const removeUser = (id) => {
        dispatch(deleteUser(getId(id)));
        setTimeout(() => {
            // dispatch(userLists());
        }, 2000);
    };
    const fields = [
        { key: 'TodayFixNumber', _style: { width: '10%' } },
        { key: 'MotorPatti', _style: { width: '10%' } },
        { key: 'JodiBracket', _style: { width: '10%' } },
        { key: 'CyclePatti', _style: { width: '10%' } },
        // {
        //     key: 'Action',
        //     _style: { width: '10%' },
        //     sorter: false,
        //     filter: false,
        // },
    ];

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Card>
                    <h3 className={classes.title}>Old Results</h3>
                    <CardContent className="p-2">
                        <CDataTable
                            items={usersData}
                            fields={fields}
                            tableFilter
                            itemsPerPageSelect
                            itemsPerPage={50}
                            sorter
                            pagination
                            scopedSlots={{
                                Action: (item) => (
                                    <td className="py-2 d-flex">
                                        {/* <CNavLink className="p-0" to={`/customers/detail/${item.Action}`}>
                                            <CBadge className="p-2" color="dark">
                                                Issue Certificate
                                            </CBadge>
                                        </CNavLink> */}
                                        {/* <IssueCertificate /> */}
                                        {/* <CNavLink className="p-0 mx-2" to={`/customers/edit-customer/${item.Action}`}>
                                            <CBadge className="p-2" color="success">
                                                Edit
                                            </CBadge>
                                        </CNavLink> */}
                                        {/* <CNavLink className="p-0 mx-2" to="/customers/list" onClick={() => removeUser(item.Action)}>
                                            <CBadge className="p-2" color="danger">
                                                Delete
                                            </CBadge>
                                        </CNavLink> */}
                                    </td>
                                ),
                            }}
                        />
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default connect(null, { userList })(GradeAUser);
