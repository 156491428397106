import React, { useReducer, useEffect, useState } from 'react';
import { Grid, Paper, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { loanList } from '../../actions/loan';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CheckCircleOutlinedIcon from '@material-ui/icons/CheckCircleOutlined';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { useHistory } from 'react-router-dom';
import { APIURL } from '../../actions/config';
import Bill from '../Billing/Bill';
import Pdf from 'react-to-pdf';
import { pdfFromReact } from 'generate-pdf-from-react-html';
import { CNavLink } from '@coreui/react';
import { toast } from 'react-toastify';

const ref = React.createRef();

const token = localStorage.getItem('token');
const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};

const UserDetail = (props) => {
    const useStyles = makeStyles((theme) => ({
        formTitle: {
            padding: theme.spacing(1, 2),
            background: 'darkgreen',
            color: '#fff',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        inputGroups: {
            padding: theme.spacing(0.5, 1),
            display: 'flex',
        },
        inputGroupssss: {
            padding: theme.spacing(0.5, 1),
            display: 'block',
        },
        margin0: {
            margin: '0px',
        },
        installments: {
            marginTop: '20px',
        },
        installment: {
            marginTop: '30px',
            display: 'flex',
            padding: '10px',
        },
        card: {
            marginTop: '50px',
        },
        cardcompleted: {
            width: '26%',
            margin: '0 auto',
        },
        title: {
            textAlign: 'center',
            padding: '16px !important',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#198754',
        },
        cancelTitle: {
            textAlign: 'center',
            padding: '16px !important',
            fontSize: '18px',
            fontWeight: 'bold',
            color: '#FF0000',
        },
        completedIcon: {
            marginRight: '6px',
        },
        crossIcon: {
            marginRight: '6px',
        },
        downloadBtn: {
            cursor: 'pointer',
        },
        secTitle: {
            margin: '10px 5px 5px',
            fontSize: '18px',
            fontWeight: '600',
        },
        profile: {
            width: '100px',
            margin: '0 auto',
            marginBottom: '30px',
            height: '100px',
        },
        profileImg: {
            width: '100px',
            height: '100px',
            margin: '0 auto',
            display: 'flex',
        },
        details: {
            margin: '20px auto',
            display: 'flex',
            justifyContent: 'center',
        },
        card: {
            margin: '0px 10px',
        },
        profileCard: {
            padding: '20px',
        },
        Paper: {
            height: '400px',
            padding: '20px',
        },
        Papers: {
            height: '200px',
            padding: '20px',
        },
    }));
    const { id } = useParams();

    const [UserDetail, setUserDetail] = useState();
    const User = UserDetail?.data?.user;
    const classes = useStyles();

    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow',
        };

        fetch(`${APIURL}/api/v1/users/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setUserDetail(result);
            });
    }, [dispatch]);
    const [type, setType] = useState();

    const issueCer = () => {
        var myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer null');
        myHeaders.append('Content-Type', 'application/json');

        var raw = JSON.stringify({
            isCertified: type,
        });

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            body: raw,
            redirect: 'follow',
        };

        fetch(`https://alive-tuna-cuff-links.cyclic.app/api/v1/users/${id}`, requestOptions)
            .then((response) => response.text())
            .then((result) => {
                toast.success('Certificate issued successfully!');
                history.push('/customers/list');
            })
            .catch((error) => console.log('error', error));
    };
    return (
        <div>
            <Grid item xs={6} style={{ margin: '0 auto' }}>
                <Paper style={{ borderRadius: '0px' }}>
                    <Typography
                        style={{ borderRadius: '0px', justifyContent: 'center' }}
                        variant="h5"
                        component="h3"
                        className={classes.formTitle}
                    >
                        User Details
                    </Typography>
                </Paper>
                <Grid item xs={12} style={{ padding: '0px', display: 'flex', borderRadius: '0px' }}>
                    <Grid item xs={12} className={classes.card} style={{ margin: '0px', borderRadius: '0px' }}>
                        <Paper className={classes.Paper} style={{ margin: '0px', borderRadius: '0px' }}>
                            <Grid item xs={12} className={classes.inputGroups}>
                                <p className={classes.margin0}>
                                    <b>Id</b>
                                </p>
                                : {`${User?.id}`}
                            </Grid>
                            <Grid item xs={12} className={classes.inputGroups}>
                                <p className={classes.margin0}>
                                    <b>Email</b>
                                </p>
                                : {`${User?.email}`}
                            </Grid>
                            <Grid item xs={12} className={classes.inputGroups}>
                                <p className={classes.margin0}>
                                    <b>Wallet Amount</b>
                                </p>
                                : {`${User?.isCertified ? User?.isCertified : 0}`}
                            </Grid>
                            <Grid item xs={12} className={classes.inputGroupssss} >
                                <p className={classes.margin0}>
                                    <b>Add New Wallet Amount :</b>
                                </p>
                            
                                <input
                                    type="number"
                                    class="form-control"
                                    name="lastName"
                                    aria-describedby="lastName"
                                    onChange={(e) => {
                                        setType(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.inputGroups}
                                style={{ justifyContent: 'center', marginTop: '20px', color: '#fff' }}
                            >
                                <Button className="btn-crt1" onClick={issueCer}>
                                    Add Amount
                                </Button>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default UserDetail;
