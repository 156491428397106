import React, { useReducer, useEffect, useState } from 'react';
import { Grid, Button, Icon, TextField, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { editUser } from '../../actions/user';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { APIURL } from '../../actions/config';
import { Formik } from 'formik';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import './style.css';
import defaultImg from './../../images/logo.png';
const initialState = { alt: '', src: '' };

const token = localStorage.getItem('token');
const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};

const EditUser = (props) => {
    const [{ alt, src }, setPreview] = useState(initialState);

    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
            marginTop: '20px',
            width: '200px',
            borderRadius: '0px',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
        root: {
            padding: theme.spacing(3, 2),
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 400,
            marginBottom: '10px',
        },
        formTitle: {
            padding: theme.spacing(1, 2),
            textAlign: 'center',
            background: 'darkgreen',
            color: '#fff',
        },
        submitButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
        inputGroups: {
            padding: theme.spacing(0, 1),
        },
    }));
    const [UserDetail, setUserDetail] = useState();
    const [profileImage, setProfileImage] = useState();
    const [image, setImgae] = useState();
    const User = UserDetail?.user;
    const dispatch = useDispatch();
    const { id } = useParams();
    let history = useHistory();

    useEffect(() => {
        // Get Users
        var requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow',
        };

        fetch(`${APIURL}/api/v1/users/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setUserDetail(result.data);
            });

        // Upload photo
        var formdata = new FormData();
        formdata.append('photo', profileImage);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow',
        };

        fetch(`${APIURL}/api/v1/users/uploadPhoto`, requestOptions)
            .then((response) => response.json())
            .then((result) => setImgae(result.Location))
            .catch((error) => console.log('error', error));
    }, [dispatch, profileImage]);

    const classes = useStyles();

    const fileHandler = (event) => {
        const { files } = event.target;
        setPreview(
            files.length
                ? {
                      src: URL.createObjectURL(files[0]),
                      alt: files[0].name,
                  }
                : initialState
        );
        setProfileImage(event.target.files[0]);
    };
    return (
        <div>
            <Grid item xs={10} style={{ margin: '0 auto' }}>
                <Paper>
                    <Typography variant="h5" component="h3" className={classes.formTitle}>
                        Edit User
                    </Typography>
                    <Typography component="p">{props.formDescription}</Typography>

                    <Formik
                        initialValues={{
                            firstName: User?.firstName,
                            lastName: User?.lastName,
                            relation: User?.relation,
                            relativeFirstName: User?.relativeFirstName,
                            relativeLastName: User?.relativeLastName,
                            mobile: User?.mobile,
                            adharNo: User?.adharNo,
                            gender: User?.gender,
                            dob: User?.dob,
                            state: User?.state,
                            distt: User?.distt,
                            city: User?.city,
                            pinCode: User?.pinCode,
                            correspondAddress: User?.correspondAddress,
                            permanentAddress: User?.permanentAddress,
                            nearAbout: User?.nearAbout,
                            photo: User?.photo,
                        }}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            values.photo = image ? image : User?.photo;
                            dispatch(editUser(values, id));
                            if (values !== '') {
                                setTimeout(() => {
                                    history.push('/customers/list');
                                }, 3000);
                            }
                        }}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form noValidate onSubmit={handleSubmit} id="json-form" className={classes.root}>
                                <Grid item xs={12} style={{ display: 'flex' }}>
                                    <Grid item xs={12} className={classes.inputGroups}>
                                        <div class="mb-2">
                                            <label for="lastName" class="form-label">
                                                Amount
                                            </label>
                                            <input
                                                type="number"
                                                class="form-control"
                                                defaultValue={values.lastName ? values.lastName : User?.lastName}
                                                name="lastName"
                                                aria-describedby="lastName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} className={classes.submitButtonWrapper}>
                                    <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                        Edit User
                                    </Button>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </div>
    );
};

export default EditUser;
