import React, { useReducer, useState, useEffect } from 'react';
import { Grid, Button, Paper, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { addCashbook } from '../../actions/cashbook';
import { toast } from 'react-toastify';

const token = localStorage.getItem('token');
const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};
const CashBook = (props) => {
    const useStyles = makeStyles((theme) => ({
        button: {
            margin: theme.spacing(1),
            marginTop: '20px',
            width: '200px',
            borderRadius: '0px',
        },
        leftIcon: {
            marginRight: theme.spacing(1),
        },
        rightIcon: {
            marginLeft: theme.spacing(1),
        },
        iconSmall: {
            fontSize: 20,
        },
        root: {
            padding: theme.spacing(3, 2),
        },
        container: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 400,
            marginBottom: '10px',
        },
        formTitle: {
            padding: theme.spacing(1, 2),
            textAlign: 'center',
            background: 'darkgreen',
            color: '#fff',
        },
        submitButtonWrapper: {
            display: 'flex',
            justifyContent: 'center',
        },
        inputGroups: {
            padding: theme.spacing(0, 1),
        },
    }));

    const [title, setTitle] = useState();
    const [credit, setCredit] = useState();
    const [debit, setDebit] = useState();
    const [total, setTotal] = useState();
const [num, setNum] = useState();
    const dispatch = useDispatch();

    const classes = useStyles();

    const handleTitle = (e) => {
        setTitle(e.target.value);
    };
    const handleDebit = (e) => {
        setDebit(e.target.value);
    };
    const handleCredit = (e) => {
        setCredit(e.target.value);
    };
    const handleTot = (e) => {
        setTotal(e.target.value);
    };

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer null");

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch("https://alive-tuna-cuff-links.cyclic.app/api/v1/checkListCat", requestOptions)
        .then(response => response.json())
        .then(result => setNum(result))
        .catch(error => console.log('error', error));
    }, [dispatch]);
    console.log(num)
    return (
        <div>
            <Grid item xs={8} style={{ margin: '0 auto' }}>
                <Paper>
                    <Typography variant="h5" component="h3" className={classes.formTitle}>
                        Current Numbers
                    </Typography>
                    <Typography component="p">{props.formDescription}</Typography>

                    <Formik
                        initialValues={{
                            sign1: title,
                            sign2: debit ? debit : 0,
                            sign3: credit ? credit : 0,
                            sign4: total ? total : 0,
                        }}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            dispatch(addCashbook(values));
                        }}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form noValidate onSubmit={handleSubmit} id="json-form" className={classes.root}>
                                <Grid item xs={12} className="d-flex">
                                <p className={classes.margin0}>
                                    <b>Today Fix Number</b>
                                </p>
                                : {`${num?.data?.checklistparent[0]?.sign1}`}
                            </Grid>
                            <Grid item xs={12} className="d-flex">
                                <p className={classes.margin0}>
                                    <b>Motor Patti</b>
                                </p>
                                : {`${num?.data?.checklistparent[0]?.sign2}`}
                            </Grid>
                            <Grid item xs={12} className="d-flex">
                                <p className={classes.margin0}>
                                    <b>Jodi (Bracket)</b>
                                </p>
                                : {`${num?.data?.checklistparent[0]?.sign3}`}
                            </Grid>
                            <Grid item xs={12} className="d-flex">
                                <p className={classes.margin0}>
                                    <b>Cycle Patti</b>
                                </p>
                                : {`${num?.data?.checklistparent[0]?.sign4}`}
                            </Grid>
                            </form>
                        )}
                    </Formik>
                </Paper>
                <Paper>
                    <Typography variant="h5" component="h3" className={classes.formTitle}>
                        Add New Numbers
                    </Typography>
                    <Typography component="p">{props.formDescription}</Typography>

                    <Formik
                        initialValues={{
                            sign1: title,
                            sign2: debit ? debit : 0,
                            sign3: credit ? credit : 0,
                            sign4: total ? total : 0,
                        }}
                        enableReinitialize={true}
                        onSubmit={(values) => {
                            dispatch(addCashbook(values));
                            setTimeout(() => {
                                window.location.reload();
                            }, 2000);
                        }}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                            <form noValidate onSubmit={handleSubmit} id="json-form" className={classes.root}>
                               
                                <Grid item xs={12} className={classes.inputGroups}>
                                    <div class="mb-2">
                                        <label for="title" class="form-label">
                                            Today Fix Number
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            defaultValue={values.title}
                                            name="title"
                                            aria-describedby="title"
                                            onChange={handleTitle}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroups}>
                                    <div class="mb-2">
                                        <label for="debit" class="form-label">
                                            Motor Patti
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            defaultValue={debit}
                                            name="debit"
                                            aria-describedby="debit"
                                            onChange={handleDebit}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroups}>
                                    <div class="mb-2">
                                        <label for="credit" class="form-label">
                                            Jodi (Bracket)
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            defaultValue={credit}
                                            name="credit"
                                            aria-describedby="credit"
                                            onChange={handleCredit}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.inputGroups}>
                                    <div class="mb-2">
                                        <label for="total" class="form-label">
                                            Cycle Patti
                                        </label>
                                        <input
                                            type="text"
                                            class="form-control"
                                            value={total}
                                            name="total"
                                            aria-describedby="total"
                                            onChange={handleTot}
                                            onBlur={handleBlur}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} className={classes.submitButtonWrapper}>
                                    <Button type="submit" variant="contained" color="primary" className={classes.button}>
                                        Save
                                    </Button>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Paper>
            </Grid>
        </div>
    );
};

export default CashBook;
