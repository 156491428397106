import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Card, Grid, CardContent, Button } from '@material-ui/core';
import { gridSpacing } from '../../store/constant';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import '@coreui/coreui/dist/css/coreui.css';
import { CNavLink, CBadge } from '@coreui/react';
import { CDataTable } from '@coreui/react';
import { closeLoan, filteredLoans, loanList } from '../../actions/loan';
import GradeCCustomers from '../Reports/GradeCCustomers';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import ReactToPdf from 'react-to-pdf';
import { pdfFromReact } from 'generate-pdf-from-react-html';
import { APIURL } from '../../actions/config';
import certificate from './../../assets/images/cert.png';

const ref = React.createRef();

const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [6, 10],
};

const token = localStorage.getItem('token');
const header = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
};

const useStyles = makeStyles({
    table: {
        minWidth: 350,
    },
    imgproduct: {
        width: '20px',
        height: 'auto',
    },
    title: {
        background: 'darkgreen',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
    },
    view: {
        background: '#eee',
        color: '#fff',
    },
    edit: { background: '#4CAF50', color: '#fff' },
    delete: { background: '#d11a2a', color: '#fff' },
    icons: {
        width: '18px',
        height: '18px',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    filterInputs: {
        margin: '0px 10px ',
    },
    button: {
        padding: '5px',
    },
});

const GradeCUser = () => {
    const { id } = useParams();

    const [UserDetail, setUserDetail] = useState();
    const [Sign, setSign] = useState();
    const User = UserDetail?.data?.user;

    const dispatch = useDispatch();
    let history = useHistory();

    useEffect(() => {
        var requestOptions = {
            method: 'GET',
            headers: header,
            redirect: 'follow',
        };

        fetch(`${APIURL}/api/v1/users/${id}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setUserDetail(result);
            });

        //
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
        };

        fetch('https://risksf.ittechxpert.com/api/v1/checkListCat', requestOptions)
            .then((response) => response.json())
            .then((result) => setSign(result))
            .catch((error) => console.log('error', error));
    }, [dispatch]);
    // console.log(Sign?.data?.checklistparent?.[0]?.sign1);
    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Card>
                    <div className="wrapper" ref={ref}>
                        <div className="certificate">
                            <img src={certificate} alt="certificate-img" />
                            <div className="certificate-num">{User?.unique_id}</div>
                            <div className="certificate-name">
                                <h1>
                                    {User?.firstName} {User?.lastName}
                                </h1>
                                <p>
                                    For the excellent performance that successfully led to the completion of Certified Ethical Hacker
                                    Certification conducted at
                                    <span>Riskstifle CyberSecurity Solutions</span>
                                </p>
                            </div>
                            <div className="signa">
                                <img src={Sign?.data?.checklistparent?.[0]?.sign1} alt="certificate-img" />
                            </div>
                            <div className="signb">
                                <img src={Sign?.data?.checklistparent?.[0]?.sign2} alt="certificate-img" />
                            </div>
                        </div>
                    </div>
                </Card>
                <ReactToPdf targetRef={ref} filename="div-blue.pdf" options={options} scale={0.89} y={0.05}>
                    {({ toPdf }) => <button onClick={toPdf}>Generate pdf</button>}
                </ReactToPdf>
            </Grid>
        </Grid>
    );
};

export default connect(null, { loanList })(GradeCUser);
