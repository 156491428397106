export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const ADD_USER = 'ADD_USER';
export const USER_LIST = 'USER_LIST';
export const USER_DETAIL = 'USER_DETAIL';
export const USER_DELETE = 'USER_DELETE';
export const EDIT_USER = 'EDIT_USER';
export const ADD_LOAN = 'ADD_LOAN';
export const LOAN_LIST = 'LOAN_LIST';
export const LOAN_DETAIL = 'LOAN_DETAIL';
export const LOAN_DELETE = 'LOAN_DELETE';
export const EDIT_LOAN = 'EDIT_LOAN';
export const ADD_INSTALLMENT = 'ADD_INSTALLMENT';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const CURRENT_DAY_LIST = 'CURRENT_DAY_LIST';
export const LOAN_CLOSE = 'LOAN_CLOSE';
export const CASHBOOK_LIST = 'CASHBOOK_LIST';
export const CASHBOOK_DELETE = 'CASHBOOK_DELETE';
export const ADD_CASHBOOK = 'ADD_CASHBOOK';
