import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Formik } from 'formik';
import { Card, Grid, CardContent } from '@material-ui/core';
import { gridSpacing } from '../../store/constant';
import { useSelector, useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import '@coreui/coreui/dist/css/coreui.css';
import { CNavLink, CBadge } from '@coreui/react';
import { CDataTable } from '@coreui/react';
import { closeLoan, filteredLoans, loanList } from '../../actions/loan';
import GradeBCustomers from '../Reports/GradeBCustomers';
import dateFormat from 'dateformat';
import moment from 'moment';
import { toast } from 'react-toastify';

const useStyles = makeStyles({
    table: {
        minWidth: 350,
    },
    imgproduct: {
        width: '20px',
        height: 'auto',
    },
    title: {
        background: 'darkgreen',
        color: '#fff',
        textAlign: 'center',
        padding: '10px',
    },
    view: {
        background: '#eee',
        color: '#fff',
    },
    edit: { background: '#4CAF50', color: '#fff' },
    delete: { background: '#d11a2a', color: '#fff' },
    icons: {
        width: '18px',
        height: '18px',
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
    },
    filterInputs: {
        margin: '0px 10px ',
    },
    button: {
        padding: '5px',
    },
});

const GradeBUser = () => {
    const fields = [
        { key: 'loanNo', _style: { width: '10%' } },
        { key: 'customer', _style: { width: '10%' } },
        { key: 'amount', _style: { width: '10%' } },
        { key: 'loanAmount', _style: { width: '10%' } },
        { key: 'monthlyInstallment', _style: { width: '10%' } },
        {
            key: 'Action',
            _style: { width: '10%' },
        },
    ];
    const classes = useStyles();
    const [signa, setSign1] = useState();
    const [signb, setSign2] = useState();

    const [profileImage, setProfileImage] = useState();

    const fileHandler = (event) => {
        var formdata = new FormData();
        formdata.append('image', event.target.files[0]);

        const token = localStorage.getItem('token');
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://risksf.ittechxpert.com/api/v1/image', requestOptions)
            .then((response) => response.json())
            .then((result) => setSign1(result.data.images.image))
            .catch((error) => console.log('error', error));
    };
    const fileHandler1 = (event) => {
        var formdata = new FormData();
        formdata.append('image', event.target.files[0]);

        const token = localStorage.getItem('token');
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${token}`);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            headers: myHeaders,
            redirect: 'follow',
        };

        fetch('https://risksf.ittechxpert.com/api/v1/image', requestOptions)
            .then((response) => response.json())
            .then((result) => setSign2(result.data.images.image))
            .catch((error) => console.log('error', error));
    };

    const fileUpload = (event) => {
        console.log(signa);
        if (signa == undefined && signb == undefined) {
        } else {
            var myHeaders = new Headers();
            myHeaders.append('Content-Type', 'application/json');

            var raw = JSON.stringify({
                sign1: signa,
                sign2: signb,
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow',
            };

            fetch('https://risksf.ittechxpert.com/api/v1/checkListCat', requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    toast.success('Signatures uploaded successfully!');
                })
                .catch((error) => console.log('error', error));
        }
    };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <Card style={{ textAlign: 'center' }}>
                    <h3 className={classes.title}>Uplaod Signatures</h3>
                    <div style={{ padding: '50px 10px' }}>
                        First Signature:{' '}
                        <input
                            id="file-input"
                            accept="image/*"
                            type="file"
                            onChange={(w) => {
                                fileHandler(w);
                            }}
                        />
                        Second Signature:{' '}
                        <input
                            id="file-input"
                            accept="image/*"
                            type="file"
                            onChange={(w) => {
                                fileHandler1(w);
                            }}
                        />
                    </div>
                    <button
                        className="btn-crt"
                        style={{ marginBottom: '20px' }}
                        onClick={() => {
                            fileUpload();
                        }}
                    >
                        Upload
                    </button>
                </Card>
            </Grid>
        </Grid>
    );
};

export default connect(null, { loanList })(GradeBUser);
